@import "node_modules/bootstrap/scss/bootstrap";

:root {
    --tl-color: #000;
    --tl-pin-text-color: #000;
}


.tl-brief-tour {
    --tl-color: rgb(250, 148, 29);
}

.tl-medium-tour {
    --tl-color: rgb(14, 147, 148);
}

.tl-extended-tour {
    --tl-color: rgb(244, 48, 69);
}

.tl-general-tour {
    --tl-color: rgb(244, 48, 69);
}

.tl-tour {
    --tl-color: rgb(244, 48, 69);
}


.it-timeline {
    padding-top: 30px;
}

.it-timeline-wrapper .it-now-label {
    position: absolute;
    top: 50%;
    z-index: 10;
    font-size: 1rem;
    font-family: "Roboto Mono", monospace;
    color: var(--tl-color);
    ;
    font-weight: 600
}

.it-timeline-wrapper .row {
    position: relative;
    padding-top: 48px
}

.it-timeline-wrapper .row:after {
    content: "";
    width: 8px;
    background: var(--tl-color);
    position: absolute;
    left: 38px;
    top: -16px;
    bottom: 16px;
}

.it-timeline-wrapper.small .row:after {
    left: 26px;
    width: 5px;
}

.it-timeline-wrapper .timeline-element {
    padding: 18px 0 18px 40px;
    position: relative
}

.it-timeline-wrapper .timeline-element .card {
    background: none
}

.it-timeline-wrapper .it-pin-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: -24px;
    z-index: 4;
    left: 6px
}

.it-timeline-wrapper .it-pin-wrapper .pin-icon {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 8px solid #fff;
    margin-right: 16px;
    background: var(--tl-color);
}

.it-timeline-wrapper .it-pin-wrapper .pin-icon-small {
    width: 22px;
    border: 3px solid #fff;
    height: 22px;
    border-radius: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    background: var(--tl-color);
}

.it-timeline-wrapper .it-pin-wrapper svg {
    width: 24px;
    height: 24px;
    fill: var(--tl-color);
}

.it-timeline-wrapper .it-pin-wrapper .pin-text {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background: var(--tl-pin-text-color);
    border-radius: 0px;
    font-family: "Roboto Mono", monospace
}

.it-timeline-wrapper .it-pin-wrapper .pin-text span {
    padding: 4px 16px;
    display: block;
    position: relative
}

// .it-timeline-wrapper .it-pin-wrapper .pin-text span:after {
//     content: "";
//     width: 10px;
//     height: 10px;
//     background: var(--tl-color);;
//     border-radius: 3px;
//     position: absolute;
//     left: -4px;
//     top: 50%;
//     transform: translateY(-50%) rotate(45deg)
// }

.it-timeline-wrapper .it-pin-wrapper.it-now .pin-icon {
    background: var(--tl-color);
}

.it-timeline-wrapper .it-pin-wrapper.it-now svg {
    fill: #fff
}

.it-timeline-wrapper .it-pin-wrapper.it-now .pin-text {
    background: var(--tl-color);
}

.it-timeline-wrapper .it-pin-wrapper.it-now .pin-text span:after {
    background: var(--tl-color);
}

.it-timeline-wrapper .it-pin-wrapper.it-evidence .pin-icon {
    background: white !important;
    border: 3px solid black;
}

.it-timeline-wrapper .it-pin-wrapper.it-evidence .pin-icon-small {
    background-image: url("../../../public/media/dot.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white !important;
    border: 3px solid black;
}

.it-timeline-wrapper .it-pin-wrapper.it-evidence .pin-text {
    background: var(--tl-pin-text-color);
}

.it-timeline-wrapper .it-pin-wrapper.it-evidence .pin-text span:after {
    background: var(--tl-pin-text-color);
}

.it-timeline-wrapper .card-title {
    text-transform: uppercase
}