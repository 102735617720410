.separator {
    width: 1px;
    height: 40px;
    background-color: lightgrey;
}

.brand {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}