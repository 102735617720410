@import "node_modules/bootstrap/scss/bootstrap";

@include media-breakpoint-up(lg) {
    .tour-card {
        min-height: 80vh;
    }
}

.tour-card {
    gap: 1px;
}

@include media-breakpoint-up(md) {
    .tour-card {
        gap: 0px;
    }
}

.tour-card-body {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 60px 30px !important;
}

.tour-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-top: 20px;
    // padding-bottom: 20px;
    padding: 0 !important;
}

.brief-tour {
    background-image: url("../../../public/media/brief-tour.jpg");
}

.medium-tour {
    background-image: url("../../../public/media/medium-tour.jpg");
}


.tour {
    background-image: url("../../../public/media/medium-tour.jpg");
}

.extended-tour {
    background-image: url("../../../public/media/extended-tour.jpg");
}

.tour-card-bg {
    background-position: center;
    background-size: cover;
}

.tour-card-title {
    color: white !important;
    text-shadow: -2px 0px 8px black;
    font-size: 25px;
    font-weight: 600 !important;
}

.tour-card-layer {
    position: absolute;
    top: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.674);
    width: 100%;
    height: 100%;

}

.tall-tour-card {
    min-height: 70vh;
}