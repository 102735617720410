.breadcumb-cont {
    background-color: var(--bs-black);
    color: white;

    padding: 20px 0px 20px 10px;

    
    .breadcrumb-item > a {
        color: var(--bs-white);
    }
    
}

.breadcrumb-item.active {
    color: beige !important;
}

.breadcrumb-item::before {
    color: #757575 !important;
}

.breadcrumb {
    margin: 0 !important;
}