@import "node_modules/bootstrap/scss/bootstrap";


* {
  font-family: Roboto, sans-serif;
}

p {
  font-weight: 300 !important;
}

h1, h2, h3 {
  font-weight: 700;
}

.App {
  min-height: 100vh;
  overflow-x: hidden;
}

.btn {
  border-radius: 0px;
  font-weight: bold;
}

.btn-light {
  border: 2px solid var(--bs-dark) ;
  background-color: var(--bs-white) ;
}

.btn-light:hover {
  color: red;
  border-color: transparent;
  background-color: var(--bs-white);
}

.card {
  border-radius: 0;
  border-color: transparent;
}

.col-even {
  background-color: #f5f5f5;
}

.col-odd {
  background-color: #fafafa;
}