@import "node_modules/bootstrap/scss/bootstrap";


.progress {
    background-color: rgb(123, 123, 123) !important;
    border-radius: 0px !important;
}

.progress-bar {
    background-color: var(--bs-light) !important;
    transition: none !important;
}